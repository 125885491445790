
import {
  IonButton,
  IonContent,
  IonPage,
  IonInput,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonHeader,
  IonItem,
  IonLabel,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonSelectOption,
  IonSelect,
  IonTextarea,
  IonCheckbox,
  IonList,
  toastController
} from '@ionic/vue';

export default {
  name: 'FormLeads',
  components: { 
    IonButton,
    IonContent,
    IonPage,
    IonInput,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonHeader,
    IonItem,
    IonLabel,
    IonSpinner,
    IonTitle,
    IonToolbar,
    IonSelectOption,
    IonSelect,
    IonCheckbox,
    IonList,
    IonTextarea
  },
  data () {
    return {
      loading: false,
      name: {
        value: '',
        valid: true
      },
      phone: {
        value: '',
        valid: true
      },
      email: {
        value: '',
        valid: true
      },
      company: {
        value: '',
        valid: true
      },
      country: {
        value: '',
        valid: true
      },
      contactPlace: {
        value: '',
        valid: true
      },
      comments: {
        value: '',
        valid: true
      },
      categories: {
        value: [] as Array<any>,
        valid: true
      }
    }
  },
  methods: {
    async openToastSuccess () {
      (await toastController
      .create({
        message: 'Registrado exitosamente',
        duration: 500
      })).present();
    },
    async openToastError () {
      (await toastController
      .create({
        message: 'Enviado a la cola de sincronización',
        duration: 1000
      })).present();
    },
    async showInvalidInputToast () {
      (await toastController
      .create({
        message: 'Revisa los campos marcados',
        duration: 1000
      })).present();
    },

    
    checkInputsValidity () {
      let allValid = true;

      if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test((this as any).email.value)) {
        allValid = false;
        (this as any).email.valid = false;
      }

      if ((this as any).name.value.length < 3) {
        allValid = false;
        (this as any).name.valid = false;
      }

      if (/[^0-9]/.test((this as any).phone.value)) {
        allValid = false;
        (this as any).phone.valid = false;
      }

      if ((this as any).country.value.length < 1) {
        allValid = false;
        (this as any).country.valid = false;
      }

      if ((this as any).contactPlace.value === '') {
        allValid = false;
        (this as any).contactPlace.valid = false;
      }

      if ((this as any).categories.value.filter((category: any) => category.checked).length === 0) {
        allValid = false;
        (this as any).categories.valid = false;
      }

      return allValid;
    },
    resetInputs () {

      (this as any).name.value = '';
      (this as any).name.valid = true;
      
      (this as any).phone.value = '';
      (this as any).phone.valid = true;
      
      (this as any).email.value = '';
      (this as any).email.valid = true;
      
      
      (this as any).company.value = '';
      (this as any).company.valid = true;
      
      (this as any).country.value = '';
      (this as any).country.valid = true;
      
      (this as any).contactPlace.value = '';
      (this as any).contactPlace.valid = true;
      
      (this as any).categories.value.forEach((category: any, ix: number) => {
        (this as any).categories.value[ix].checked = false;
      });
      (this as any).categories.valid = true;
      
      (this as any).comments.value = '';
      (this as any).comments.valid = true;
    
    },
    async handleSubmit () {

      if (!this.checkInputsValidity()) {
        this.showInvalidInputToast();
        (this as any).loading = false;
        return;
      }

      const payload = {
        name: (this as any).name.value,
        phone: (this as any).phone.value,
        email: (this as any).email.value,
        company: (this as any).company.value,
        country: (this as any).country.value,
        comments: (this as any).comments.value,
        contactPlace: (this as any).contactPlace.value,
        categories: (this as any).categories.value.filter((category: any) => category.checked)
      };

      const inserted = await (this as any).$store.dispatch('insertLead', payload);

      this.resetInputs()

      if (inserted) {
        this.openToastSuccess();
      } else {
        this.openToastError();
      }
    },
    populateCategories () {
      (this as any).categories.value = (this as any).$store.state.categorias.map((cat: any) => ({
        id: cat.id,
        nombre: cat.nombre,
        checked: false,
      }));
    }
  },
  computed: {
    paises () {
      return (this as any).$store.state.paises
    },
    lugaresContacto () {
      return (this as any).$store.state.lugaresContacto
    }
  },
  watch: {
    '$store.state.categorias': function () {
      (this as any).populateCategories();
    }
  },
  mounted () {
    (this as any).populateCategories();
  }
}
